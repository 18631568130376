import React from 'react'
import Container from '../../../../components/Container'
import DataTable from '../../../../components/DataTable'
import { nameEntity, namePageTitle, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import { genericMaskWithTwoZero } from '../../../../utlis/mask'

const ProductAtributesList = (): JSX.Element => (
  <Container
    pageTitle={namePageTitle}
    portletTitle={'Listagem'}
    breadcrumb={breadcrumbList}
    tools={[toolsList]}
  >
    <DataTable
      source={nameSource}
      entity={nameEntity}
      format={{ orderBy: 'localization' }}
      onlyParent
      notHasChildren
      headers={headers}
      customHeaders={[
        {
          name: 'Percentual',
          field: 'percentage',
          sortable: false,
          element: e => <span>{genericMaskWithTwoZero(e.percentage)}</span>
        },
        {
          name: 'Percentual Capital',
          field: 'percentage_capital',
          sortable: true,
          element: e => (
            <span>
              {genericMaskWithTwoZero(e.childCategories?.[0]?.percentage)}
            </span>
          )
        },
        {
          name: 'Prazo médio Capital',
          field: 'average_deadline_capital',
          sortable: true,
          element: e => <span>{e.childCategories?.[0]?.average_deadline}</span>
        }
      ]}
    />
  </Container>
)

export default ProductAtributesList
