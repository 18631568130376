import React, { useState, useCallback, useEffect } from 'react'
import { Container, IconRemove, Panel, StockItem, Td, Th } from './style'
import { useToast } from '../../../../../../../../../../hooks/toast'
import { DetailsTab } from '../../details'
import { useProduct } from '../../../../../providers/product/ProductProvider'
import { Attributes } from '../../../../../../services/api'
import { Input, Select } from '../../../../../../../../../../components/Form'
import { Alert } from '../../../../../../../../../../components/Alert'
import api from '../../../../../../../../../../services/api'
import { CompositionTable } from '../../HasComposition/Table'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

type TableProps = {
  attributesList: Attributes[]
}

type ProductListFetch = {
  id: number
  name: string
  prices: string
  product?: {
    id: number
    name: string
  }
}

type ProductListType = {
  id: number
  name: string
}

type StockListType = {
  id: number
  name: string
  product?: {
    id: number
    name: string
  }
}

export const Table = ({ attributesList }: TableProps): JSX.Element => {
  const { productType, stock, setStock, unitMensured } = useProduct()

  const [productList, setProductList] = useState<ProductListType[]>([])
  const [selected, setSelected] = useState(false)
  const [stocksList, setStocksList] = useState<StockListType[]>([])

  const [usedProducts, setUsedProducts] = useState<number[]>([])

  const isTypeSaleOrResale = () =>
    productType === 'venda' ||
    productType === 'revenda' ||
    productType === 'projeto-especial'

  const { addToast } = useToast()
  const [currentStock, setCurrentStock] =
    useState<{ id: number; index: number; name: string }>()

  useEffect(() => {
    ;(async () => {
      const { data } = await api.get<ProductListFetch[]>('warehouse/stocks')
      setStocksList(data)

      const products = data.map(s => ({
        id: s.product?.id,
        name: s.product?.name
      }))
      const filtered = products?.filter(
        (value, index, self) => index === self.findIndex(t => t.id === value.id)
      )

      setProductList(filtered)
    })()
  }, [])
  /*   useEffect(() => {
    if (!stock) return
    let soma = '0'
    const used = []
    for (let i = 0; i < stock[stockIndex].composition.length; i++) {
      if (stock[stockIndex].composition[i]?.stock_id) {
        used.push(stock[stockIndex].composition[i].stock_id)
      }
      if (!stock[stockIndex].composition[i]) continue
      const subtotal = BRL(stock[stockIndex].composition[i].amount).multiply(
        BRL(stock[stockIndex].composition[i].cost)
      )
      soma = BRL(subtotal).add(BRL(soma)).format()
    }
    // setTotal(soma)
    setUsedProducts(used)
  }, [setUsedProducts, stock])
 */
  const [alertRemoveStock, setAlertRemoveStock] = useState(false)
  const handlerOnClickButtonConfirmRemoveStock = useCallback(
    async (id: number, index: number) => {
      try {
        if (currentStock.id) {
          await api.delete(`warehouse/stocks/delete/${String(id)}`)
        }
        setStock(stock => {
          const copy = [...stock]
          copy[index] = undefined
          return copy
        })
        setAlertRemoveStock(false)
        addToast({
          type: 'success',
          title: 'Produto removido com sucesso.'
        })
      } catch (err: any) {
        setAlertRemoveStock(false)
        if (err.response.status === 403) {
          addToast({
            type: 'error',
            title: err.response.data.message
          })
          return
        }
        addToast({
          type: 'error',
          title: 'Produto não pode ser removido, pois ainda está em uso.'
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addToast, currentStock]
  )

  const handlerOnClickButtonCancelRemoveStock = useCallback(() => {
    setAlertRemoveStock(false)
  }, [])

  const [alertOldProduct, setAlertOldProduct] = useState<boolean[]>([])
  const [oldProduct, setOldProduct] = useState<
    { show: boolean; id: number; name: string }[]
  >([])
  const onSearchOldProduct = useCallback(
    async (index: number) => {
      try {
        const data = await api.get(
          `warehouse/produtos/view/${stock[index].details.old_product}`
        )
        if (!data.data) {
          addToast({
            type: 'error',
            title: 'Produto não encontrado.'
          })
        }
        setOldProduct(prev => {
          const copy = [...prev]
          copy[index] = {
            show: false,
            id: data.data.id,
            name: data.data.nome
          }
          return copy
        })
        setStock((prev: any) => {
          const copy = [...prev]
          copy[index].details = {
            ...copy[index].details,
            old_product_name: data.data.nome
          }
          return copy
        })

        setAlertOldProduct(prev => {
          const copy = [...prev]
          copy[index] = true
          return copy
        })
      } catch (err: any) {
        setAlertOldProduct(prev => {
          const copy = [...prev]
          copy[index] = false
          return copy
        })
        addToast({
          type: 'error',
          title: 'Produto não encontrado.'
        })
      }
    },
    [addToast, setStock, stock]
  )
  const handlerOnClickButtonConfirmOldProduct = async (index: number) => {
    setOldProduct(prev => {
      const copy = [...prev]
      copy[index] = {
        ...copy[index],
        show: true
      }
      return copy
    })
    setAlertOldProduct([])
  }

  const handlerOnClickButtonCancelOldProduct = () => {
    setAlertOldProduct([])
  }

  return (
    <Container className="table-responsive">
      {stock.map((stockItem, index) => {
        return (
          stockItem && (
            <StockItem
              key={stockItem.id}
              className="panel-group"
              id="accordion"
            >
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h4 className="panel-title">
                    <div
                      onClick={() => {
                        setStock((prev: any) => {
                          const copy = [...prev]
                          copy[index].active = true
                          return copy
                        })
                        setTimeout(() => {
                          setStock((prev: any) => {
                            const copy = [...prev]
                            copy[index].open = !copy[index].open
                            return copy
                          })
                        }, 200)
                      }}
                    >
                      {stockItem?.name?.toUpperCase() || 'NOVO PRODUTO'}
                      {!stockItem?.open ? (
                        <FaChevronDown size={13} />
                      ) : (
                        <FaChevronUp size={13} />
                      )}
                    </div>
                  </h4>
                </div>
                {(stockItem.open || stockItem.active) && (
                  <Panel
                    id={`${index}`}
                    className={`panel-collapse ${!stockItem?.id ? 'in' : ''}`}
                    open={stockItem.open}
                  >
                    <div className="panel-body">
                      <table className="table margin-bottom-0">
                        <tbody>
                          <React.Fragment key={index}>
                            <div style={{ display: 'none' }}>
                              <Input name={`stock.${index}.id`} type="hidden" />
                            </div>
                            <tr>
                              <Th active isTypeSaleOrResale={true}>
                                Unidade de medidas
                              </Th>

                              {attributesList.map(
                                ({ name, parent_id, isChecked }: any) =>
                                  parent_id === null && (
                                    <Th
                                      key={name}
                                      active={!!isChecked}
                                      isTypeSaleOrResale={true}
                                    >
                                      {name}
                                    </Th>
                                  )
                              )}
                              <th
                                align="center"
                                style={{ textAlign: 'center', width: '20%' }}
                                colSpan={2}
                              >
                                Estoque
                              </th>
                              {isTypeSaleOrResale() ? (
                                <th
                                  align="center"
                                  style={{ textAlign: 'center', width: '20%' }}
                                  colSpan={3}
                                >
                                  Preço
                                </th>
                              ) : null}
                              {productType === 'locacao' ? (
                                <th
                                  align="center"
                                  style={{ textAlign: 'center', width: '10%' }}
                                  colSpan={1}
                                >
                                  Preço
                                </th>
                              ) : null}
                              <Th active isTypeSaleOrResale={true}>
                                Ações
                              </Th>
                            </tr>
                            <tr>
                              <th>Atual</th>
                              <th>Ponto de Reposição</th>
                              {isTypeSaleOrResale() && (
                                <>
                                  <th>Custo</th>
                                  <th>Margem de lucro</th>
                                  <th>Venda</th>
                                </>
                              )}
                              {productType === 'locacao' && (
                                <>
                                  <th>Locação</th>
                                </>
                              )}
                            </tr>
                            <tr key={index}>
                              <td>
                                <Select
                                  name={`stock.${index}.unit_mensured_id`}
                                  className="form-control"
                                  options={unitMensured.map(({ id, name }) => ({
                                    name: name,
                                    value: id
                                  }))}
                                  controlled
                                  blank
                                  rules={{ required: true }}
                                />
                              </td>
                              <>
                                {attributesList.map(
                                  (
                                    { parent_id, childrenList, id, isChecked },
                                    indexAtribute
                                  ) =>
                                    parent_id === null &&
                                    isChecked && (
                                      <Td active={isChecked} key={id}>
                                        <Select
                                          className="form-control"
                                          name={`stock.${index}.atributes.${indexAtribute}.value`}
                                          id="Selecione"
                                          options={childrenList.map(itm => ({
                                            value: itm.id,
                                            name: itm.name
                                          }))}
                                          controlled
                                          blank
                                          value={
                                            stockItem?.atributes[indexAtribute]
                                              ?.value
                                          }
                                          onChange={({ currentTarget }) => {
                                            setStock(prev => {
                                              const copy = [...prev]
                                              copy[index].atributes[
                                                indexAtribute
                                              ].value = currentTarget.value
                                              return copy
                                            })
                                          }}
                                          rules={{ required: true }}
                                        />
                                        <Input
                                          name={`stock.${index}.atributes.${indexAtribute}.key`}
                                          value={id}
                                          type="hidden"
                                        />
                                      </Td>
                                    )
                                )}
                                <td>
                                  <Input
                                    name={`stock.${index}.current_stock`}
                                    className="form-control"
                                    type="number"
                                    rules={{ required: true }}
                                    readOnly={!!stockItem?.id}
                                    style={{ paddingRight: '5px' }}
                                  />
                                </td>
                                <td>
                                  <Input
                                    name={`stock.${index}.replacement_point`}
                                    className="form-control"
                                    type="number"
                                    rules={{ required: true }}
                                    style={{ paddingRight: '5px' }}
                                  />
                                </td>
                              </>
                              {isTypeSaleOrResale() && (
                                <>
                                  <td style={{ width: '150px' }}>
                                    <Input
                                      name={`stock.${index}.price_cost`}
                                      placeholder="0.00"
                                      className="form-control"
                                      type="text"
                                      price
                                    />
                                  </td>
                                  <td style={{ width: '150px' }}>
                                    <Input
                                      name={`stock.${index}.margin_profit`}
                                      placeholder="0.00"
                                      onKeyPress={event => {
                                        const regex = /^[0-9.]+$/
                                        if (!regex.test(event.key)) {
                                          event.preventDefault()
                                        }
                                      }}
                                      className="form-control"
                                      type="text"
                                      price
                                    />
                                  </td>
                                  <td style={{ width: '150px' }}>
                                    <Input
                                      name={`stock.${index}.price_sale`}
                                      placeholder="0.00"
                                      onKeyPress={event => {
                                        const regex = /^[0-9.]+$/
                                        if (!regex.test(event.key)) {
                                          event.preventDefault()
                                        }
                                      }}
                                      className="form-control"
                                      type="text"
                                      price
                                    />
                                  </td>
                                </>
                              )}
                              {productType === 'locacao' && (
                                <>
                                  <td style={{ width: '150px' }}>
                                    <Input
                                      name={`stock.${index}.location_value`}
                                      placeholder="0.00"
                                      className="form-control"
                                      type="text"
                                      price
                                    />
                                  </td>
                                </>
                              )}
                              <td className="actions">
                                <IconRemove
                                  className="top"
                                  onClick={() => {
                                    const count = stock.reduce(
                                      (amount, current) => {
                                        return current ? amount + 1 : amount
                                      },
                                      0
                                    )
                                    if (count > 1) {
                                      setCurrentStock({
                                        id: stockItem.id,
                                        index,
                                        name: stockItem.name
                                      })
                                      setAlertRemoveStock(true)
                                    }
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              {DetailsTab(
                                index,
                                onSearchOldProduct,
                                setStock,
                                stock,
                                oldProduct,
                                handlerOnClickButtonConfirmOldProduct,
                                handlerOnClickButtonCancelOldProduct,
                                alertOldProduct,
                                productType
                              )}
                            </tr>
                            {[
                              'venda',
                              'revenda',
                              'semi-acabado',
                              'projeto-especial'
                            ].includes(productType) && (
                              <tr>
                                {CompositionTable({
                                  stockIndex: index,
                                  stock,
                                  setStock,
                                  productList,
                                  stocksList,
                                  setProductList,
                                  selected,
                                  setSelected,
                                  usedProducts,
                                  setUsedProducts,
                                  productType
                                })}
                              </tr>
                            )}
                          </React.Fragment>
                        </tbody>
                      </table>
                    </div>
                  </Panel>
                )}
              </div>
            </StockItem>
          )
        )
      })}
      <hr />
      <button
        type="button"
        onClick={() =>
          setStock(prev => {
            const copy = [...prev]
            const validStock = copy.find(o => o)
            const newAtributes = validStock.atributes.map(
              ({ key }: { key: string }) => ({
                key,
                value: 0
              })
            )
            return [
              ...copy,
              {
                open: false,
                active: true,
                atributes: newAtributes,
                composition: [
                  { amount: 0, cost: 0, name: '', subtotal: 0, stock_id: '' }
                ]
              }
            ]
          })
        }
        className="btn dark btn-sm sbold uppercase"
      >
        <span
          className="fa fa-plus"
          aria-hidden="true"
          style={{ marginRight: '5px' }}
        />
        variação
      </button>
      <Alert
        message={`Tem certeza que deseja excluir o registro ${currentStock?.name}?`}
        onClickCancellButton={handlerOnClickButtonCancelRemoveStock}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveStock(
            Number(currentStock.id),
            Number(currentStock.index)
          )
        }
        isActive={alertRemoveStock}
      />
    </Container>
  )
}
